import Vue from 'vue'
import App from './App.vue' 

Vue.config.productionTip = false

import VueAwesomeSwiper from 'vue-awesome-swiper'
import router from './router'

// import style
import 'swiper/css/swiper.css'

Vue.use(VueAwesomeSwiper)

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
