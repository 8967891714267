<template>
  <Swiper
    ref="pageswiper"
    :class="isMobile ? 'swiper mobile_css' : 'swiper pc_css'"
    :options="swiperOption"
    :style="swiperStyle"
  >
    <swiper-slide>
      <div class="page bg1">
        <div class="page_content_con">
          <div class="content_con">
            <img
              class="content_img1"
              src="https://static-file.pokedo.cn/honnverse_web/images/page1.png"
            />
          </div>
        </div>
        <div class="bottom_con">
          <img
            class="next_img"
            src="https://static-file.pokedo.cn/honnverse_web/images/next_sign.png"
            @click="swiperTo(1)"
          />
        </div>
      </div>
    </swiper-slide>
    <swiper-slide>
      <div class="page bg2">
        <div class="page_content_con">
          <div class="content_con">
            <img
              class="content_img2"
              src="https://static-file.pokedo.cn/honnverse_web/images/page2.png"
            />
          </div>
        </div>
        <div class="bottom_con">
          <img
            class="next_img"
            src="https://static-file.pokedo.cn/honnverse_web/images/next_sign.png"
            @click="swiperTo(2)"
          />
        </div>
      </div>
    </swiper-slide>
    <swiper-slide>
      <div class="page bg2">
        <div class="page_content_con">
          <div class="content_con">
            <img
              class="content_img3"
              src="https://static-file.pokedo.cn/honnverse_web/images/page3.png"
            />
          </div>
        </div>
        <div class="bottom_con">
          <img
            class="next_img"
            src="https://static-file.pokedo.cn/honnverse_web/images/next_sign.png"
            @click="swiperTo(3)"
          />
        </div>
      </div>
    </swiper-slide>
    <swiper-slide>
      <div class="page bg2">
        <div class="page_content_con">
          <div class="content_con">
            <img
              class="content_img4"
              src="https://static-file.pokedo.cn/honnverse_web/images/page4.png"
            />
          </div>
        </div>
        <div class="bottom_con">
          <img
            class="next_img"
            src="https://static-file.pokedo.cn/honnverse_web/images/next_sign.png"
            @click="swiperTo(4)"
          />
        </div>
      </div>
    </swiper-slide>
    <swiper-slide>
      <div class="page">
        <div class="content_con bg5">
          <div class="page_content_con">
            <div class="content_img5_con">
              <div class="download_con" :style="'background-image:url(' + getRandomUrl('https://static-file.pokedo.cn/honnverse_web/images/qrcode_subscribe_bg.png') +')'">
                <img
                  class="download_qrcode"
                  :src="getRandomUrl('https://static-file.pokedo.cn/honnverse_web/images/qrcode_subscribe.png')"
                />
              </div>
              <img
                class="content_img5"
                src="https://static-file.pokedo.cn/honnverse_web/images/page5.png"
              />
            </div>
          </div>
        </div>
        <div v-if="isMobile" class="bottom_con5">
          <div class="company_con">
            <div class="logo_con">
              <img
                class="logo_img"
                src="https://static-file.pokedo.cn/honnverse_web/images/logo.png"
              />
              <div class="company_name_con">
                <div class="zh">虹宇宙</div>
                <div class="en">Honnverse</div>
              </div>
            </div>
            <div class="address_account_con">
              <div class="address_con">
                <div>联系地址: 北京市 朝阳区三里屯西五街5号院</div>
                <div>
                    合作邮箱: honnverse@inmyshow.com
                </div>
              </div>
              <div class="account_con">
                <div class="account_item_con">
                  <div @touchstart="accountMouseEnter(1)">
                    微博官方账号
                  </div>
                  <div
                    class="account_qrcode_con_normal account_qrcode_con1"
                    v-show="accountCodeShow(1)"
                  >
                    <img
                      class="download_qrcode"
                      :src="getRandomUrl('https://static-file.pokedo.cn/honnverse_web/images/weibo_qrcode.jpg')"
                    />
                    <div>关注官方微博</div>
                  </div>
                </div>
                <div class="account_item_con">
                  <div @touchstart="accountMouseEnter(2)">
                    微信公众号
                  </div>
                  <div
                    class="account_qrcode_con_normal account_qrcode_con2"
                    v-show="accountCodeShow(2)"
                  >
                    <img
                      class="download_qrcode"
                      :src="getRandomUrl('https://static-file.pokedo.cn/honnverse_web/images/weixin_qrcode.jpg')"
                    />
                    <div>关注官方公众号</div>
                  </div>
                </div>
                <div class="account_item_con">
                  <div @touchstart="accountMouseEnter(3)">
                    抖音官方账号
                  </div>
                  <div
                    class="account_qrcode_con_normal account_qrcode_con3"
                    v-show="accountCodeShow(3)"
                  >
                    <img
                      class="download_qrcode"
                      :src="getRandomUrl('https://static-file.pokedo.cn/honnverse_web/images/douyin_qrcode.jpg')"
                    />
                    <div>关注官方抖音号</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="icp_con">
            <div class="icp_content_con">
              <div style="display:flex; margin:0 auto;">
                <a target="_blank" href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=11010502048802" style="display:inline-block;text-decoration:none;height:20px;line-height:20px;">
                  <img src="https://static-file.pokedo.cn/honnverse_web/images/bj_gong_an.png" style="float:left;"/>
                  <p style="float:left;height:20px;line-height:20px;margin: 0px 0px 0px 5px; color:#939393;">京公网安备 11010502048802号</p>
                </a>
                <a target="_blank" href="http://www.beian.gov.cn/portal/index"  style="display:inline-block;text-decoration:none;height:20px;line-height:20px; color:#939393; padding-left:10px;">京ICP备2021003020号-3</a>
              </div>
              <div class="icp_doc_con">
                <a @click="showAgreement(1)">用户协议</a>
                <div class="vertical_line"></div>
                 <a @click="showAgreement(2)">隐私政策</a>
              </div>
            </div>
            <div>Copyright ©2020-2021 Honnverse. All Rights Reserved</div>
          </div>
        </div>
        <div v-else class="bottom_con5">
          <div class="account_qrcodes_con">
            <div class="qrcodes_con">
              <div
                class="account_qrcode_con_normal account_qrcode_con1"
                v-show="accountCodeShow(1)"
              >
                <img
                  class="download_qrcode"
                  :src="getRandomUrl('https://static-file.pokedo.cn/honnverse_web/images/weibo_qrcode.jpg')"
                />
                <div>关注官方微博</div>
              </div>
              <div
                class="account_qrcode_con_normal account_qrcode_con2"
                v-show="accountCodeShow(2)"
              >
                <img
                  class="download_qrcode"
                  :src="getRandomUrl('https://static-file.pokedo.cn/honnverse_web/images/weixin_qrcode.jpg')"
                />
                <div>关注官方公众号</div>
              </div>
              <div
                class="account_qrcode_con_normal account_qrcode_con3"
                v-show="accountCodeShow(3)"
              >
                <img
                  class="download_qrcode"
                  :src="getRandomUrl('https://static-file.pokedo.cn/honnverse_web/images/douyin_qrcode.jpg')"
                />
                <div>关注官方抖音号</div>
              </div>
            </div>
          </div>
          <div class="company_con">
            <img
              class="logo_img"
              src="https://static-file.pokedo.cn/honnverse_web/images/logo.png"
            />
            <div class="company_name_con">
              <div class="zh">虹宇宙</div>
              <div class="en">Honnverse</div>
            </div>
            <div v-if="!isMobile" class="bottom_verticle_line"></div>
            <div class="address_account_con">
              <div class="address_con">
                <div>合作邮箱</div>
                <div>honnverse@inmyshow.com</div>
              </div>
              <div class="address_con">
                <div>联系地址</div>
                <div>北京市 朝阳区三里屯西五街5号院</div>
              </div>
              <div class="account_con">
                <div
                  @mouseenter="accountMouseEnter(1)"
                  @mouseleave="accountMouseLeave()"
                >
                  微博官方账号
                </div>
                <div
                  @mouseenter="accountMouseEnter(2)"
                  @mouseleave="accountMouseLeave()"
                >
                  微信公众号
                </div>
                <div
                  @mouseenter="accountMouseEnter(3)"
                  @mouseleave="accountMouseLeave()"
                >
                  抖音官方账号
                </div>
              </div>
            </div>
          </div>
          <div class="icp_con">
            <div style="display:flex; margin:0 auto;">
              <a target="_blank" href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=11010502048802" style="display:inline-block;text-decoration:none;height:20px;line-height:20px;">
                <img src="https://static-file.pokedo.cn/honnverse_web/images/bj_gong_an.png" style="float:left;"/>
                <p style="float:left;height:20px;line-height:20px;margin: 0px 0px 0px 5px; color:#939393;">京公网安备 11010502048802号</p>
              </a>
              <a target="_blank" href="http://www.beian.gov.cn/portal/index"  style="display:inline-block;text-decoration:none;height:20px;line-height:20px; color:#939393; padding-left:10px;">京ICP备2021003020号-3</a>
            </div>
            <div class="icp_doc_con">
              <div>Copyright ©2020 - 2024 Honnverse. All Rights Reserved</div>
              <a @click="showAgreement(1)">用户协议</a>
              <div class="vertical_line"></div>
              <a @click="showAgreement(2)">隐私政策</a>
            </div>
          </div>
        </div>
      </div>
    </swiper-slide>
    <div class="swiper-pagination" slot="pagination"></div>
  </Swiper>
</template>

<script>
export default {
  name: "HomePage",
  data() {
    return {
      swiperOption: {
        notNextTick: true, //notNextTick是一个组件自有属性，如果notNextTick设置为true，组件则不会通过NextTick来实例化swiper，也就意味着你可以在第一时间获取到swiper对象，假如你需要刚加载遍使用获取swiper对象来做什么事，那么这个属性一定要是true
        direction: "vertical", //水平方向移动
        grabCursor: true, //鼠标覆盖Swiper时指针会变成手掌形状，拖动时指针会变成抓手形状
        setWrapperSize: true, //Swiper使用flexbox布局(display: flex)，开启这个设定会在Wrapper上添加等于slides相加的宽或高，在对flexbox布局的支持不是很好的浏览器中可能需要用到。
        slidesPerView: 1, //设置slider容器能够同时显示的slides数量(carousel模式)。可以设置为数字（可为小数，小数不可loop），或者 'auto'则自动根据slides的宽度来设定数量。loop模式下如果设置为'auto'还需要设置另外一个参数loopedSlides。
        mousewheel: true, //开启鼠标滚轮控制Swiper切换。可设置鼠标选项，默认值false
        mousewheelControl: true, //同上
        resistanceRatio: 0, //抵抗率。边缘抵抗力的大小比例。值越小抵抗越大越难将slide拖离边缘，0时完全无法拖离。本业务需要

        pagination: {
          el: ".swiper-pagination",
          clickable: true,
          type: "custom",
          renderCustom: function(swiper, current, total) {
            var span_style =
              "background:transparent; width:14px; height:14px; padding-right:40px; padding-bottom:10px;";
            var img_select_style = "width:14px; height:14px;";
            var img_normal_style = "width:8px; height:8px;";
            if (
              /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
                navigator.userAgent
              )
            ) {
              span_style =
                "background:transparent; width:14px; height:14px; padding-right:10px; padding-bottom:10px;";
              img_select_style = "width:10px; height:10px;";
              img_normal_style = "width:4px; height:4px;";
            }

            var paginationHtml = "";
            for (var i = 1; i <= total; i++) {
              if (i == current) {
                paginationHtml +=
                  '<span class="swiper-pagination-bullet swiper-pagination-bullet-active" style="' +
                  span_style +
                  '"><img style="' +
                  img_select_style +
                  '" src="https://static-file.pokedo.cn/honnverse_web/images/pagination_select.png" /></span>';
              } else {
                paginationHtml +=
                  '<span class="swiper-pagination-bullet"  style="' +
                  span_style +
                  '"><img style="' +
                  img_normal_style +
                  '" src="https://static-file.pokedo.cn/honnverse_web/images/pagination_normal.png" /></span>';
              }
            }
            return paginationHtml;
          },
        },
      },
      swiperStyle: "",
      isMinHeight: false,
      showNum: 0,
    };
  },
  watch: {},
  computed: {
    swiper() {
      return this.$refs.pageswiper.$swiper;
    },

    isMobile() {
      if (
        /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
          navigator.userAgent
        )
      ) {
        return true;
      } else {
        return false;
      }
    },
  },
  mounted() {
    this.updateSwiperHeight();
    window.onresize = () => {
      this.updateSwiperHeight();
    };
  },
  methods: {
    updateSwiperHeight() {
      console.log("this.screenHeight:", this.screenHeight);
      let swiperHeight = window.innerHeight;
      this.isMinHeight = false;
      if (!this.isMobile && swiperHeight < 700) {
        swiperHeight = 700;
        this.isMinHeight = true;
      }
      this.swiperOption.height = swiperHeight;
      this.swiperStyle = "height:" + swiperHeight + "px";
    },
    accountCodeShow(num) {
      return num == this.showNum;
    },
    accountMouseEnter(num) {
      if (num == this.showNum) {
        this.showNum = 0;
        return;
      }
      this.showNum = num;
    },
    accountMouseLeave() {
      this.showNum = 0;
    },
    swiperTo(num) {
      this.swiper.slideTo(num);
    },
    showAgreement(num) {
      console.log("showAgreement" + this.$router);
      this.$router.push({path: '/agreement', query: {num: num}})
    },

    getRandomUrl(url) {
      return url + "?" + Math.random() * 999999;
    }
  },
};
</script>

<style lang="scss" scoped>
.pc_css {
  width: 100%;
  height: 100%;

  .swiper-pagination-custom {
    right: 30px;
    top: 60%;
    transform: translate3d(0, -50%, 0);
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: center;
  }

  /**自定义翻页进度条 end*/

  .page_container {
    width: 100%;
    height: 100%;
  }
  .swiper_min {
    height: 1080px;
  }

  .page {
    width: 100%;
    height: 100%;
    overflow: hidden;
    display: flex;
    flex-direction: column;
  }

  .bg1 {
    background: url("https://static-file.pokedo.cn/honnverse_web/images/bg1.jpg");
    background-size: cover;
  }

  .bg2 {
    background: url("https://static-file.pokedo.cn/honnverse_web/images/bg2.jpg");
    background-size: cover;
  }

  .bg5 {
    background: url("https://static-file.pokedo.cn/honnverse_web/images/bg5.jpg");
    background-size: cover;
  }

  .page_content_con {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 1;
  }

  .content_con {
    z-index: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    flex: 1;
  }

  .content_img1 {
    width: 47.7%;
    min-width: 400px;
    max-width: 916px;
    height: auto;
  }

  .content_img1_min_height {
    height: 74.5%;
    min-height: 572px;
    max-height: 805px;
    width: auto;
  }

  .content_img2 {
    max-width: 1221px;
    min-width: 400px;
    width: 63%;
    height: auto;
  }

  .content_img3 {
    width: 54%;
    min-width: 400px;
    max-width: 1050px;
    height: auto;
  }

  .content_img4 {
    width: 57%;
    max-width: 1108px;
    min-width: 400px;
    height: auto;
  }

  .content_img5_con {
    position: relative;
    max-width: 954px;
    min-width: 400px;
    width: 49%;
  }

  .content_img5 {
    z-index: 0;
    width: 100%;
    height: auto;
  }

  .download_con {
    background-size: contain;
    width: 14%;
    max-width: 135px;
    max-height: 154px;
    height: 21.2%;
    position: absolute;
    bottom: 1.6%;
    right: 20%;
    z-index: 10;
    border-radius: 14px;
    border: solid #bdbdbd 1px;
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 11.8%;
    font-weight: bold;
    color: #000000;
  }

  .download_qrcode {
    width: 80%;
    height: auto;
    margin-top: 5%;
  }

  .bottom_con {
    z-index: 1;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    height: 110px;
  }

  .next_img {
    width: 2%;
    min-width: 20;
    max-width: 39px;
    margin-bottom: 30px;
  }

  .bottom_con5 {
    z-index: 1;
    width: 100%;
    display: flex;
    flex-direction: column;
  }

  .account_qrcodes_con {
    display: flex;
    justify-content: center;
    height: 0px;
    background: #000000;
  }

  .qrcodes_con {
    display: flex;
    position: relative;
    padding-left: 180px;
  }

  .account_qrcode_con_normal {
    background-image: url("https://static-file.pokedo.cn/honnverse_web/images/qrcode_bg.png");
    background-size: contain;
    background-repeat: no-repeat;
    width: 145px;
    height: 176px;
    padding-top: 6px;
    position: absolute;
    bottom: -70px;
    z-index: 10;
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 16px;
    font-weight: bold;
    color: #000000;
    cursor: grab;
  }

  .account_qrcode_con1 {
    left: 0px;
  }

  .account_qrcode_con2 {
    left: 140px;
  }

  .account_qrcode_con3 {
    left: 280px;
  }

  .company_con {
    height: 110px;
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 800px;
  }

  .logo_img {
    width: 98px;
    height: 50px;
  }

  .company_name_con {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 5px 37px 5px 21px;
    font-size: 18px;
    color: #101820;
    font-weight: bold;
  }

  .bottom_verticle_line {
    width: 1px;
    height: 60px;
    background: #101820;
  }

  .address_account_con {
    padding: 5px 0px 5px 32px;
    display: flex;
    flex-direction: column;
  }

  .address_con {
    display: flex;
    color: #242424;
    font-size: 14px;
  }

  .address_con div {
    margin-right: 20px;
  }

  .account_con {
    display: flex;
    font-size: 18px;
    color: #fdfdfd;
    font-weight: 500;
    margin-top: 8px;
  }

  .account_con div {
    background: #000000;
    border-radius: 6px;
    padding: 3px 16px;
    margin-right: 10px;
    position: relative;
    overflow: visible;
  }

  .account_show_con {
    position: absolute;
    width: 146px;
    height: 176px;
  }

  .icp_con {
    height: 60px;
    background: black;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    color: #ffffff;
    min-width: 950px;
    padding-top: 4px;
  }

  .icp_doc_con {
    display: flex;
    padding-top: 4px;
    align-items: center;
  }

  .icp_doc_con a {
    margin:0px 8px;
    color: white;
  }

  .icp_doc_con .vertical_line {
    width: 1px;
    height: 14px;
    background: #ffffff;
  }
}

.mobile_css {

    .swiper-pagination {
        right: 10px;
    }

  .swiper-pagination-custom {
    position:absolute;
    left: auto;
    right: 10px;
    top: 50%;
    width: 50px;
    transform: translate3d(0, -50%, 0);
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: center;
  }

  .swiper {
    width: 100%;
    height: 100vh;
    margin: 0px;
    padding: 0px;
  }

  .page {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
  }

  .bg1 {
    background: url("https://static-file.pokedo.cn/honnverse_web/images/bg1.jpg");
    background-size: cover;
  }

  .bg2 {
    background: url("https://static-file.pokedo.cn/honnverse_web/images/bg2.jpg");
    background-size: cover;
  }

  .bg5 {
    background: url("https://static-file.pokedo.cn/honnverse_web/images/bg5.jpg");
    background-size: cover;
  }

  .page_content_con {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 1;
  }

  .content_con {
    z-index: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    flex: 1;
  }

  .content_img1 {
    width: 80%;
    height: auto;
  }

  .content_img2 {
    width: 90%;
    height: auto;
  }

  .content_img3 {
    width: 90%;
    height: auto;
  }

  .content_img4 {
    width: 90%;
    height: auto;
  }

  .content_img5_con {
    position: relative;
    width: 90%;
  }

  .content_img5 {
    z-index: 0;
    width: 100%;
    height: auto;
  }

  .download_con {
    background-size:cover;
    background-repeat: no-repeat;
    width: 28%;
    max-width: 270px;
    max-height: 308px;
    height: 39%;
    position: absolute;
    padding-top: 4px;
    bottom: -20%;;
    right: 36%;
    z-index: 100;
    border-radius: 6px;
    display: flex;
    flex-direction: column;
    align-items: center;
    cursor: grab;
    color: #000000;
    border-radius: 14px;
    border: solid #bdbdbd 1px;
  }

  .download_qrcode {
    width: 80%;
    height: auto;
    margin-top: 5%;
  }

  .bottom_con {
    z-index: 1;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    height: 110px;
  }

  .next_img {
    width: 2%;
    min-width: 20;
    max-width: 39px;
    margin-bottom: 30px;
  }

  .bottom_con5 {
    z-index: 1;
    width: 100%;
    display: flex;
    flex-direction: column;
  }

  .account_qrcodes_con {
    display: flex;
    justify-content: center;
    height: 20px;
    background: red;
  }

  .qrcodes_con {
    display: flex;
    position: relative;
    background: green;
  }

  .account_qrcode_con_normal {
    background-image: url("https://static-file.pokedo.cn/honnverse_web/images/qrcode_bg.png");
    background-size: contain;
    background-repeat: no-repeat;
    width: 100px;
    height: 121px;
    padding-top: 4px;
    position: absolute;
    bottom: 20px;
    z-index: 10;
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 12px;
    font-weight: bold;
    color: #000000;
    cursor: grab;
  }

  .account_qrcode_con1 {
    left: -8px;
  }

  .account_qrcode_con2 {
    left: -12px;
  }

  .account_qrcode_con3 {
    left: -8px;
  }

  .company_con {
    padding-top: 6px;
    height: 160px;
    display: flex;
    flex-direction: column;
  }

  .logo_con {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .logo_img {
    width: 80px;
    height: 40px;
  }

  .company_name_con {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 5px 37px 5px 21px;
    font-size: 14px;
    color: #101820;
    font-weight: bold;
  }

  .bottom_verticle_line {
    width: 1px;
    height: 60px;
    background: #101820;
  }

  .address_account_con {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .address_con {
    display: flex;
    flex-direction: column;
    color: #242424;
    font-size: 14px;
  }

  .account_con {
    display: flex;
    font-size: 11px;
    color: #fdfdfd;
    font-weight: 500;
    margin-top: 8px;
  }

  .account_item_con {
    background: #000000;
    border-radius: 6px;
    padding: 4px 6px;
    margin-right: 6px;
    position: relative;
    overflow: visible;
  }

  .account_show_con {
    position: absolute;
    width: 146px;
    height: 176px;
  }

  .icp_con {
    padding: 6px 0px;
    background: black;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: 11px;
    color: #ffffff;
  }

  .icp_content_con {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .icp_doc_con {
    display: flex;
    align-items: center;
  }

  .icp_doc_con a {
    margin-right: 19px;
    color: white;
  }

  .icp_doc_con .vertical_line {
    width: 1px;
    height: 14px;
    background: #ffffff;
    margin-right: 19px;
  }
}
</style>
